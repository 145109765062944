import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';

import { API_URL } from '@webapp/common/conf';
import { ApiRespCode, isSubdomain } from '@webapp/common/lib/api';
import { getSurveyTokenCookie, getUserToken } from '@webapp/common/lib/cookies';
import { getFormattedParams, ttFetch } from '@webapp/common/lib/fetch';
import { LocalStorage, LocalStorageKeys } from '@webapp/common/lib/storage';

import { AccountRoutes } from 'resources/routes';

const badToken = new Set([ApiRespCode.ERR_TOKEN_CODE, ApiRespCode.ERR_BAD_TOKEN]);

const goAuth = (reason: string): void => {
    // TODO redirect with action
    const auth = window.location.pathname.startsWith(AccountRoutes.auth.root);

    if (!auth) {
        LocalStorage.set(
            LocalStorageKeys.BACK_URL,
            `backUrl=${encodeURIComponent(window.location.pathname)}` +
                (reason ? `&reason=${encodeURIComponent(reason)}` : '')
        );
        AccountRoutes.authLogin.assign();
    }
};

const successCallback = (response) => {
    if (typeof response !== 'object') {
        console.error('successCallback invalid response type:', response);
        return Promise.reject(response);
    }
    const { code, message, status } = response;

    if (code === ApiRespCode.ERR_STATUS_CODE || code === ApiRespCode.OK_CODE) {
        return Promise.resolve(response);
    }

    if (status === 401 || badToken.has(code)) {
        return Promise.reject(goAuth(response.url.includes('get/profile') ? '' : message));
    }

    return Promise.reject(response);
};

const makeRequest = ({
    method,
    body,
    headers,
    path,
    params,
    absPath,
    callback
}: {
    method: string;
    path: string;
    headers?: Record<string, string>;
    body?: any;
    params?: Record<string, any>;
    absPath?: boolean;
    callback?: (resp) => void;
}): Promise<any> => {
    const url = absPath ? `${API_URL}${path}` : `${API_URL}/core/v1/${path}`; // TODO purge,check start/results

    const formattedParams = getFormattedParams(params);

    return ttFetch({
        method,
        retries: 1,
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json'
        }
    })(`${url}${formattedParams}`, {
        method: 'POST',
        headers,
        body
    }).then(callback || successCallback);
};

export const post = (
    path: string,
    data: JsonValue = {},
    config?: {
        absPath?: boolean;
        callback?: (resp) => void;
    }
): Promise<any> => {
    const token = getUserToken();
    data = cloneDeep(data) || {};

    if (token) {
        data['token'] = token;
    }

    const body = new FormData();
    for (const [key, val] of Object.entries(data)) {
        const value = val instanceof File ? val : isObject(val) ? JSON.stringify(val) : (val as string);

        if (value !== undefined) {
            body.append(key, value);
        }
    }

    return makeRequest({
        method: 'POST',
        body,
        path,
        absPath: config?.absPath,
        callback: config?.callback,
        headers: {
            ...(isSubdomain && { 'X-Authorization': getSurveyTokenCookie() })
        }
    });
};

export const DELETE = (path: string, absPath = false): Promise<any> => {
    const token = getUserToken();

    return makeRequest({
        method: 'DELETE',
        path,
        absPath,
        headers: {
            'T-Authorization': token
        }
    });
};

export const GET = (path: string, params?: Record<string, any>, absPath = false): Promise<any> => {
    const token = getUserToken();

    return makeRequest({
        method: 'GET',
        path,
        params,
        absPath,
        headers: {
            'T-Authorization': token || ''
        }
    });
};

export const patchOrPut =
    (method: string) =>
    (path: string, data: JsonValue = {}, params?: Record<string, any>, absPath = false): Promise<any> => {
        const token = getUserToken();
        data = cloneDeep(data) || {};

        if (token) {
            data['token'] = token;
        }

        const body = new FormData();

        for (const [key, val] of Object.entries(data)) {
            const value = val instanceof File ? val : isObject(val) ? JSON.stringify(val) : (val as string);

            if (value !== undefined) {
                body.append(key, value);
            }
        }

        return makeRequest({
            method,
            body,
            path,
            params,
            absPath,
            headers: {
                ...(isSubdomain && { 'X-Authorization': getSurveyTokenCookie() }),
                'T-Authorization': token
            }
        });
    };

export const POST = post;
export const PATCH = patchOrPut('PATCH');
export const PUT = patchOrPut('PUT');
