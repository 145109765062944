import cn from 'classnames';

import { AccountRoutes } from '@webapp/account/src/resources/routes';
import { useAuthInfoConnect } from '@webapp/common/hooks/use-auth-info-connect';
import { formatCurrency } from '@webapp/common/lib/ui';

import css from './plan.css';

const ApplyButton: FC = () => {
    const { isLoggedIn } = useAuthInfoConnect();
    const link = isLoggedIn ? AccountRoutes.ordersLicence.link : AccountRoutes.authRegister.link;

    return (
        <a className={css.addButton} href={link}>
            Подключить
        </a>
    );
};

export const Plan: FC<{
    header: ReactNode;
    hint: ReactNode;
    price: number;
    items: Array<{ icon: JSX.Element; text: string | ReactNode; selected?: boolean; className?: string }>;
}> = ({ header, hint, items, price }) => (
    <div className={css.plan}>
        <div className={css.planHeader}>{header}</div>

        <div className={css.planActions}>
            <div className={css.price}>
                <span>{formatCurrency(price)}</span>
                <div className={css.hint}>{hint}</div>
            </div>
            <ApplyButton />
        </div>
        {items.map(({ className, icon, selected, text }, idx) => (
            <div
                key={idx}
                className={cn(
                    css.planItem,
                    {
                        [css.selectedItem]: selected
                    },
                    className
                )}
            >
                {icon}
                <div>{text}</div>
            </div>
        ))}
        <ApplyButton />
    </div>
);
