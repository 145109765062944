import { LicenseNames, LicenseType } from '@webapp/account/src/resources/profile/license';
import { AtSign, CheckMark, Cloud, Infinity, LinkParam, PencilEdit, PlusUser, RedCross } from '@webapp/ui/lib/icons';
import { Tooltip } from '@webapp/ui/lib/tooltip';

import ClockIcon from 'components/ui/icons/clock.svg';

import css from './plan/plan.css';

export const plans = [
    {
        header: LicenseNames[LicenseType.ONETIME],
        hint: <span>за опрос</span>,
        price: 7990,
        items: [
            {
                icon: <Infinity />,
                text: 'Вопросов, страниц, фильтров',
                selected: true
            },
            {
                icon: <PencilEdit />,
                text: '1 опрос, 10 000 ответов'
            },
            {
                icon: <Cloud />,
                text: 'Хранилище 2 Гб '
            },
            {
                icon: <AtSign />,
                text: 'Рассылка до 2 000 всего'
            },
            {
                icon: <PlusUser />,
                text: (
                    <>
                        Панель респондентов <Tooltip>Оплачивается дополнительно</Tooltip>
                    </>
                )
            },
            {
                icon: <LinkParam />,
                text: '1 параметр ссылки'
            },
            {
                icon: <ClockIcon />,
                text: '60 дней'
            },
            {
                icon: <RedCross />,
                text: 'API'
            },
            {
                icon: <RedCross />,
                text: 'Продвинутая аналитика'
            },
            {
                icon: <RedCross />,
                text: 'Сохранение незавершённых ответов',
                className: css.red
            },
            {
                icon: <RedCross />,
                text: 'Оффлайн режим',
                className: css.red
            },
            {
                icon: <RedCross />,
                text: 'Свой SMTP',
                className: css.red
            }
        ]
    },
    {
        header: LicenseNames[LicenseType.FORYEAR],
        hint: <span>за админа ЛК</span>,
        price: 40990,
        items: [
            {
                icon: <Infinity />,
                text: 'Вопросов, страниц, фильтров',
                selected: true
            },
            {
                icon: <Infinity />,
                text: 'Опросов, ответов',
                selected: true
            },
            {
                icon: <Cloud />,
                text: 'Хранилище 10 Гб '
            },
            {
                icon: <AtSign />,
                text: 'Рассылка до 10 000 в сутки'
            },
            {
                icon: <PlusUser />,
                text: (
                    <>
                        Панель респондентов <Tooltip>Оплачивается дополнительно</Tooltip>
                    </>
                )
            },
            {
                icon: <LinkParam />,
                text: '1 параметр ссылки'
            },
            {
                icon: <ClockIcon />,
                text: '1 год'
            },
            {
                icon: <RedCross />,
                text: 'API',
                className: css.red
            },
            {
                icon: <RedCross />,
                text: 'Продвинутая аналитика'
            },
            {
                icon: <RedCross />,
                text: 'Сохранение незавершённых ответов',
                className: css.red
            },
            {
                icon: <RedCross />,
                text: 'Оффлайн режим',
                className: css.red
            },
            {
                icon: <RedCross />,
                text: 'Свой SMTP',
                className: css.red
            }
        ]
    },
    {
        header: (
            <span>
                <span>{LicenseNames[LicenseType.FORYEARPLUS]}</span>
                <span> PRO+</span>
            </span>
        ),
        hint: <span>за админа ЛК</span>,
        price: 80990,
        items: [
            {
                icon: <Infinity />,
                text: 'Вопросов, страниц, фильтров',
                selected: true
            },
            {
                icon: <Infinity />,
                text: 'Опросов, ответов',
                selected: true
            },
            {
                icon: <Cloud />,
                text: 'Хранилище 15 Гб '
            },
            {
                icon: <AtSign />,
                text: 'Рассылка до 15 000 в сутки'
            },
            {
                icon: <PlusUser />,
                text: (
                    <>
                        Панель респондентов <Tooltip>Оплачивается дополнительно</Tooltip>
                    </>
                )
            },
            {
                icon: <LinkParam />,
                text: '50 параметров ссылки'
            },
            {
                icon: <ClockIcon />,
                text: '1 год'
            },
            {
                icon: <CheckMark />,
                text: 'API',
                className: css.green
            },
            {
                icon: <CheckMark />,
                text: (
                    <>
                        Продвинутая аналитика <Tooltip>Построение кросс-таблиц</Tooltip>
                    </>
                ),
                className: css.green
            },
            {
                icon: <CheckMark />,
                text: 'Сохранение незавершённых ответов',
                className: css.green
            },
            {
                icon: <CheckMark />,
                text: 'Оффлайн режим',
                className: css.green
            },
            {
                icon: <CheckMark />,
                text: 'Свой SMTP',
                className: css.green
            }
        ]
    }
];
