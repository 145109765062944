import dayjs from 'dayjs';
import memoize from 'lodash/memoize';
import tinycolor from 'tinycolor2';

import type { LogicPageQuestion as LogicQuestion } from '@webapp/account/src/resources/logic/question';
import type { FillerQuestion } from '@webapp/account/src/resources/questions/fillers';
import { QuestionType, TYPES_NAMES } from '@webapp/common/resources/survey';

import type { Colors } from '../assets/styles/colors';
import colors from '../assets/styles/colors.json';
import type { InfoDesignModel } from '../resources/entities/info';

import { striptags, UnescapeHtml } from './utils';

export const MARGIN_BASE_UNIT = '2%';
export const HEADER_MIN_SIZE_PX = 40;
export const HEADER_MAX_SIZE_PX = 230;

export const reEmail = /^.+@.+\..+/;
export const rePhone = /^[+0-9. ()\/-]*$/;

export const isEmail = (email: string): boolean => reEmail.test(String(email));
export const isPhoneNumber = (number: string): boolean => rePhone.test(String(number));

export const calcHeaderHeight = (headerSize: number): number => {
    let headerHeight = headerSize;
    headerHeight = isNaN(headerHeight) ? 50 : headerHeight;
    headerHeight = (headerHeight * HEADER_MAX_SIZE_PX) / 100;
    headerHeight += HEADER_MIN_SIZE_PX;

    return headerHeight;
};

export const createMarginStyle = (top: number, left: number, bottom: number, right: number): CSSProperties => ({
    paddingTop: `calc(${top + 1} * ${MARGIN_BASE_UNIT})`,
    paddingLeft: `calc(${left} * 3% + 2%)`,
    paddingBottom: `calc(${bottom + 1} * ${MARGIN_BASE_UNIT})`,
    paddingRight: `calc(${right} * 3% + 2%)`
});

export const createFooterContainerStyle = (paddingLeft: number, paddingRight: number): CSSProperties => ({
    paddingTop: 0,
    paddingRight: `calc(${paddingRight} * ${MARGIN_BASE_UNIT} + 4%)`,
    paddingBottom: 0,
    paddingLeft: `calc(${paddingLeft} * ${MARGIN_BASE_UNIT} + 4%)`
});

const objectKeyCacheResolver = (params: AnyObject): string => {
    const sorted = Object.keys(params)
        .sort()
        .reduce((acc, k) => ((acc[k] = params[k]), acc), {});

    return JSON.stringify(sorted);
};

export const createTextStyle = memoize(
    ({
        bold,
        color,
        italic,
        size,
        through,
        underline
    }: {
        bold?: boolean;
        color?: string;
        italic?: boolean;
        size?: number;
        through?: boolean;
        underline?: boolean;
    }): CSSProperties => {
        const textDecoration = [];

        if (underline) {
            textDecoration.push('underline');
        }

        if (through) {
            textDecoration.push('line-through');
        }

        return {
            color: color || undefined,
            fontSize: size ? `clamp(10px, calc(${size}px / 2 + 2vw), ${size}px)` : undefined,
            textDecoration: textDecoration.length > 0 ? textDecoration.join(' ') : undefined,
            fontWeight: bold ? 'bold' : undefined,
            fontStyle: italic ? 'italic' : undefined
        };
    },
    objectKeyCacheResolver
);

export const createBackgroundStyle = ({
    brand: { background, backgroundColor, backgroundTransparent }
}: InfoDesignModel): CSSProperties => {
    if (background !== BackgroundType.COLOR) return {};

    let c = tinycolor(backgroundColor);
    if (!c.isValid()) {
        c = tinycolor('#FFF');
    }
    c.setAlpha((100 - backgroundTransparent) / 100);

    return {
        backgroundColor: c.toRgbString()
    };
};

export const createIndicatorStyles = (backgroundColor: string): CSSProperties => {
    let c = tinycolor(backgroundColor);

    if (!c.isValid()) {
        c = tinycolor('#FFF');
    }

    c.setAlpha(0.6);

    return {
        backgroundColor: c.toRgbString()
    };
};

export const strTemplate = (strings, ...keys) => {
    return (...values) => {
        const dict = values[values.length - 1] || {};
        const result = [strings[0]];
        keys.forEach((key, i) => {
            const value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    };
};

export const placeholder = (text: string): string =>
    `<p style='text-align: center;'><span style='font-size: 24px;'>${text}</span></p>`;

export const setHtml = memoize((text: string | number) => ({
    dangerouslySetInnerHTML: { __html: UnescapeHtml(text !== null ? text : '') }
}));

export function getSpecialLabel({
    name,
    params: { agreementLeftLabel, agreementLink, agreementRightLabel, textBlock },
    type
}: Partial<FillerQuestion> | Partial<LogicQuestion>): string {
    switch (type) {
        case QuestionType.TEXT_BLOCK:
            const teaser = striptags(textBlock).substr(0, 100).trim();
            return teaser ? `${teaser}...` : TYPES_NAMES[QuestionType.TEXT_BLOCK];
        case QuestionType.AGREEMENT:
            return `${UnescapeHtml(agreementLeftLabel)} ${UnescapeHtml(agreementLink)} ${UnescapeHtml(
                agreementRightLabel
            )}`;
        default:
            return name;
    }
}

export const roundFloat = (v: string | number, precision = 2): number => Number(Number(v).toFixed(precision));
export const roundInt = (v: string | number): number => Math.round(Number(v));

export enum AlignSetting {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right'
}

export const mapQuestionBlockPositionToAlignItems = {
    [AlignSetting.LEFT]: 'flex-start',
    [AlignSetting.CENTER]: 'center',
    [AlignSetting.RIGHT]: 'flex-end'
};

export const tooltipLabel = (str: string): string => {
    const v = striptags(UnescapeHtml(str));
    if (v.length < 12) return v;
    return v.substr(0, 12) + '...';
};

export enum NpsType {
    BAD = 'BAD',
    NORMAL = 'NORMAL',
    GOOD = 'GOOD'
}

export const mapNpsLabel = (type: NpsType): string =>
    ({
        [NpsType.BAD]: 'Критик',
        [NpsType.NORMAL]: 'Нейтрал',
        [NpsType.GOOD]: 'Промоутер'
    }[type]);

export type TTColor = keyof Colors;

export const getColor = (name: TTColor): string => {
    const v = colors[`--c-${name}`];
    if (!v) {
        console.error(`color not defined: ${name}`);
    }
    return v;
};

export const stopEvent = (e: any): void => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();

    if ((e as ReactUIEvent).nativeEvent) {
        // TODO review usages, called on no-react events
        (e as ReactUIEvent).nativeEvent.stopImmediatePropagation();
    }
};

// https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
export const pagination = (current: number, total: number, tail = 5): Array<number | string> => {
    const center = [current - 2, current - 1, current, current + 1, current + 2],
        filteredCenter: Array<number | string> = center.filter((p) => p > 1 && p < total),
        includeThreeLeft = current === tail,
        includeThreeRight = current === total - tail - 1,
        includeLeftDots = current > tail,
        includeRightDots = current < total - tail - 1;

    if (includeThreeLeft) filteredCenter.unshift(2);
    if (includeThreeRight) filteredCenter.push(total - 1);

    if (includeLeftDots) filteredCenter.unshift('...');
    if (includeRightDots) filteredCenter.push('...');

    const res = [1, ...filteredCenter];

    if (total > 1) {
        res.push(total);
    }

    return res;
};

export const fileSize = <T extends boolean = false>(
    size: number,
    split?: T
): T extends true ? Array<string> : string => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while (size >= 1024) {
        size /= 1024;
        ++i;
    }
    const res = [size.toFixed(1), units[i]];

    return (split ? res : res.join(' ')) as any;
};

export const humanizeAmount = <T extends boolean = false>(
    size: number,
    split?: T
): T extends true ? Array<string> : string => {
    const units = [null, 'тыс', 'мил'];
    let i = 0;
    while (size >= 1000) {
        size /= 1000;
        ++i;
    }
    const res = [size.toFixed(0), units[i]];

    return (split ? res : res.join(' ')) as any;
};

export const plural = (forms: Array<string>, n: number): string => {
    let idx;
    if (n < 1) {
        idx = 2;
    } else if (n % 10 === 1 && n % 100 !== 11) {
        idx = 0; // many
    } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
        idx = 1; // few
    } else {
        idx = 2; // one
    }
    return forms[idx] || '';
};

const locale = 'ru-RU';

const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});
export const formatCurrency = (amount: number): string => currencyFormatter.format(amount);

const amountFormatter = new Intl.NumberFormat(locale);
export const formatAmount = (amount: number): string => amountFormatter.format(amount);

export function replaceMask(masks: { [key: string]: string | number }, text: string): string {
    for (const key in masks) {
        const pattern = `{${key}}`;
        text = text.replace(new RegExp(pattern, 'g'), String(masks[key]));
    }
    return text;
}

// TODO move to selector
export const calcPercent = (val: number, max: number): number =>
    val === 0 ? 0 : Math.max(0, Math.round((val / max) * 100));

export enum ColorScheme {
    DARK = 'dark',
    LIGHT = 'light'
}

export enum HeaderTitlePosition {
    IN_HEAD = 'inhead',
    UNDER_HEAD = 'underhead',
    WITHOUT_HEAD = 'withouthead'
}

export enum BackgroundType {
    COLOR = 'COLOR',
    GRADIENT = 'GRADIENT'
}

export enum HeaderBackgroundType {
    COLOR = 'COLOR',
    IMAGE = 'IMAGE',
    TRANSPARENT = 'TRANSPARENT',
    NOT_HEAD = 'NOT_HEAD'
}

export const humanizeDuration = (secs: number, short = false): [string, string] => {
    const d = dayjs.duration(secs, 'seconds');

    if (secs < 60) {
        return [d.format('s'), short ? 'сек.' : 'секунд'];
    } else if (secs < 3600) {
        return [d.format('m'), short ? 'мин.' : 'минут'];
    }

    return [d.format('H'), short ? 'ч.' : 'часов'];
};

export const buttonColorOptions = [
    {
        label: 'Светлый',
        value: ColorScheme.LIGHT
    },
    {
        label: 'Темный',
        value: ColorScheme.DARK
    }
];

export const textColorOptions = [
    {
        label: 'Белый',
        value: ColorScheme.LIGHT
    },
    {
        label: 'Черный',
        value: ColorScheme.DARK
    }
];

// TODO do not use hardcoded values
export enum BoolAnswerText {
    TRUE = 'Да',
    FALSE = 'Нет'
}

export const boolOptions = [
    {
        label: BoolAnswerText.TRUE,
        value: '1'
    },
    {
        label: BoolAnswerText.FALSE,
        value: '0'
    }
];

export enum WidgetTypes {
    ICON = 'Иконка',
    TEXT = 'Плашка с текстом'
}

export const widgetTypesOptions = [
    {
        label: WidgetTypes.ICON,
        value: 1
    },
    {
        label: WidgetTypes.TEXT,
        value: 2
    }
];

export enum MobileDisplayOptionsTypes {
    DISABLED = 'Не отображать',
    FULL_SCREEN = 'На весь экран',
    HALF_SCREEN = 'Часть экрана по центру'
}

export const mobileDisplayOptions = [
    {
        label: MobileDisplayOptionsTypes.DISABLED,
        value: 0
    },
    {
        label: MobileDisplayOptionsTypes.FULL_SCREEN,
        value: 1
    },
    {
        label: MobileDisplayOptionsTypes.HALF_SCREEN,
        value: 2
    }
];

export enum ListPos {
    BEFORE = 'before',
    AFTER = 'after',
    SAME = 'same'
}

export const styleToObject = (style, skip = []): AnyObject => {
    const setKeys: Array<string> = Array.from(style);
    return setKeys.reduce((acc, key) => {
        if (!skip.includes(key)) {
            acc[key] = style.getPropertyValue(key);
        }
        return acc;
    }, {});
};

export const styleStrToObject = (style: string): CSSProperties =>
    style
        ? style
              .split(';')
              .map((style) => style.split(':'))
              .filter((arr) => arr.length === 2)
              .reduce((acc, item) => ({ ...acc, [item[0].trim()]: item[1].trim() }), {})
        : {};

export const styleObjectToString = (obj: AnyObject): string => {
    const keys = Object.keys(obj);
    return keys.reduce((acc, key) => acc + `${key}:${obj[key]};`, '');
};

export const copyToClipboard = (text: string): void => {
    try {
        void navigator.clipboard.writeText(text);
    } catch (e) {
        console.error(e);
    }
};

export const selectText = (element): void => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
};

export const selectTextOnClick = ({ nativeEvent }: ReactUIEvent): void => selectText(nativeEvent.target);

export const mergeValues = (vals: Array<any>, sep = ' '): string =>
    vals
        .filter(Boolean)
        .map((v) => String(v).trim())
        .join(sep);
