import { useCallback, useEffect, useMemo, useState } from 'react';

import { delUserToken, getUserToken } from '@webapp/common/lib/cookies';
import { LocalStorage, LocalStorageKeys } from '@webapp/common/lib/storage';

import { nop } from '../lib/utils';

export const useAuthInfoConnect: (logout?: () => void) => { isLoggedIn: boolean; onLogout: () => void } = (
    logout = nop
) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const onLogout = useCallback(() => {
        delUserToken();
        setIsLoggedIn(false);
        LocalStorage.remove(LocalStorageKeys.BACK_URL);
        logout();
    }, [logout]);

    useEffect(() => {
        setIsLoggedIn(!!getUserToken());
    }, []);

    return useMemo(
        () => ({
            isLoggedIn,
            onLogout
        }),
        [isLoggedIn, onLogout]
    );
};
