import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { AccountRoutes } from 'resources/routes';

import { POST } from '@webapp/account/src/libs/request';
import { useAuthInfoConnect } from '@webapp/common/hooks/use-auth-info-connect';

import { HeaderAuthMenuAccount, HeaderAuthMenuLanding } from './header-auth-menu';

import css from './header.css';

export const HeaderMenu: FC<{
    isLanding: boolean;
    hideNoAuth?: boolean;
}> = ({ isLanding, hideNoAuth }) => {
    const { isLoggedIn, onLogout } = useAuthInfoConnect();
    const init = useRef<boolean>(false);

    useEffect(() => {
        if (isLanding && isLoggedIn && !init.current) {
            POST('get/profile', undefined, {
                callback: (resp) => {
                    if (resp.status === 401) {
                        onLogout();
                    }
                }
            });
            init.current = true;
        }
    }, [isLoggedIn]);

    const renderAuthMenu = () => {
        return isLanding ? <HeaderAuthMenuLanding /> : <HeaderAuthMenuAccount />;
    };

    return (
        <div className={cn(css.item, css.mobileShow)}>
            {isLoggedIn
                ? renderAuthMenu()
                : !hideNoAuth && (
                      <div className={css.nonAuth}>
                          <a className={cn(css.link, css.login)} href={AccountRoutes.authLogin.link}>
                              Вход
                          </a>
                          <div className={css.span} />
                          <a className={cn(css.link, css.register)} href={AccountRoutes.authRegister.link}>
                              Регистрация
                          </a>
                      </div>
                  )}
        </div>
    );
};
